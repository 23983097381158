import React from "react";
import { LayoutDefault } from "../components/LayoutDefault";
import { HowWeWork } from "../components/HowWeWork";

export default ({ location }) => {
  return (
    <LayoutDefault>
      <HowWeWork />
    </LayoutDefault>
  );
};
